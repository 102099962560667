var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-text" }, [
      _c("div", { staticClass: "lineText" }, [
        _vm._v(
          "\n        Copyright ® 2017-2021 General Intelligence All rights reserved. \n    "
        )
      ]),
      _c("div", { staticClass: "lineText" }, [
        _vm._v(
          "\n         通用智能有限公司（香港）| 玑脉（上海）数据技术有限公司 \n    "
        )
      ]),
      _c("div", { staticClass: "lineText" }, [
        _vm._v(
          "\n          地址：上海市杨浦区政益路47号1002室 电话： 021-51695869 \n    "
        )
      ]),
      _c("div", { staticClass: "lineText" }, [
        _vm._v("\n        沪ICP备17047921号-14\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }