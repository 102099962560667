var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("p", [_vm._v("参数路由")]),
          _c("avue-crud", {
            attrs: { option: _vm.tableOption, data: _vm.tableData },
            scopedSlots: _vm._u([
              {
                key: "git",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      { attrs: { href: scope.row.git, target: "_blank" } },
                      [_vm._v(_vm._s(scope.row.git))]
                    )
                  ]
                }
              },
              {
                key: "menu",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/advanced-router/argument-detail",
                            query: {
                              id: scope.row.id,
                              name: scope.row.name + "的参数路由详情"
                            }
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { icon: "el-icon-check", size: "small" } },
                          [_vm._v("\n            详情\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }