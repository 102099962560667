var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.oidcIsAuthenticated
    ? _c(
        "div",
        { staticClass: "protected login-container pull-height" },
        [_c("SignedInUser")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }