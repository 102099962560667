var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("h3", { staticStyle: { color: "red" } }, [
            _vm._v(
              "./src/config/env.js中的iconfontVersion数组追加下图红框的地址即可"
            )
          ]),
          _c(
            "el-row",
            { staticClass: "icon-list", attrs: { span: 24 } },
            [
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-daohanglanmoshi02" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-daohanglanmoshi02")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-changyonglogo27" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-changyonglogo27")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-biaoge" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-biaoge")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-baidu1" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-baidu1")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-tubiao" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-tubiao")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-souhu" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-souhu")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-msnui-360" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-msnui-360")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-iframe" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-iframe")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-huanyingye" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-huanyingye")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-weixin" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-weixin")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-qq" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-qq")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-tuichu" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-tuichu")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-jiaoseguanli" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-jiaoseguanli")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-yonghuguanli" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-yonghuguanli")
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { xs: 12, sm: 6, md: 4 } },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon-caidanguanli" }),
                    _c("span", { staticClass: "icon-name" }, [
                      _vm._v("icon-caidanguanli")
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c("img", {
            staticClass: "iconfon-explace",
            attrs: { src: "/img/mock/iconfont.png", alt: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }