export default [
    {
        id: 0,
        name: "smallwei",
        git: "https://gitee.com/smallweigit/avue"
    },
    {
        id: 1,
        name: "lengleng",
        git: "https://gitee.com/log4j/pig"
    }
]