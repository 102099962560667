<template>
  <div class="volumeSet">
    <el-dialog
        style="marginTop: -60px;"
        title="编辑物模型管理"
        :visible.sync="modelShow"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :append-to-body="true">
      <div class="content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备机型">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备类别">
                <el-input v-model="form.typeName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工序">
                <el-input v-model="form.processName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="功率">
                <el-input v-model="form.power" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建人">
                <el-input v-model="form.createUserName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-input v-model="form.createTime" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button type="primary" @click.native="openInner"> + {{$t("Add functionality")}}</el-button>
        <div class="table_container">
          <template>
            <el-table
                :data="tableData"
                style="width: 100%">
              <el-table-column
                  prop="name"
                  label="字段名称"
              >
              </el-table-column>
              <el-table-column
                  prop="code"
                  label="字段标识符">
              </el-table-column>
              <el-table-column
                  prop="dataType"
                  label="数据类型">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="取值范围">
                <template slot-scope="{row}">
                  <span style="margin-left: 10px">{{ row.minValue + "~" + row.maxValue }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="状态">
                <template slot-scope="{row}">
                  <span style="margin-left: 10px">{{ row.status == 0?'正常' : '无效' }}</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
<!--      内存dialog-->
      <el-dialog
          width="900px"
          title="添加字段"
          :visible.sync="innerVisible"
          append-to-body>
        <div class="inner_content">
          <div class="table_container">
            <template>
              <el-table
                  @selection-change="handleSelectionChange"
                  :data="tableData2"
                  row-key="id"
                  ref="multipleTable"
                  style="width: 100%">
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="字段名称"
                    >
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="字段标识符">
                </el-table-column>
                <el-table-column
                    prop="dataType"
                    label="数据类型">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="取值范围">
                  <template slot-scope="{row}">
                    <span style="margin-left: 10px">{{ row.minValue + "~" + row.maxValue }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态">
                  <template slot-scope="{row}">
                    <span style="margin-left: 10px">{{ row.status == 0?'正常' : '无效' }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="innerVisible = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="innerSure">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>

      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button @click="closeModel">{{$t("Cancel")}}</el-button>
        <el-button type="primary" @click="makeSure">{{$t("Yes")}}</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "edit",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    selectItem:Object
  },
  data() {
    return {
      form:{
        name:'哈哈哈'
      },
      tableData:[],
      tableData2:[],
      innerVisible:false,
      multipleSelection: []
    }
  },
  created(){
    this.form = JSON.parse(JSON.stringify(this.selectItem))
    this.tableData = JSON.parse(JSON.stringify(this.selectItem.iotColumnsList)) || []
    this.getfunction()
    this.getModel()
  },
  methods: {
    // 获取当前设备模型
    async getModel(){
      const  res = await this.request.get(this.proApis.MACHINEMODELSELECTBYMACHINEMODELID+'?id=' + this.selectItem.id)
      console.log(res)
      this.tableData = res.data

    },
    openInner(){
      this.innerVisible = true
      this.$nextTick(()=>{
        if(this.$refs.multipleTable) {
          this.tableData.forEach(row => {
            this.tableData2.forEach(item=>{
              if(row.id === item.id) {
                this.$refs.multipleTable.toggleRowSelection(item,true);
              }
            })
          });
        }
      })


    },
    innerSure(){
      console.log(this.multipleSelection )
      this.tableData = this.multipleSelection
      this.innerVisible = false
    },
    handleSelectionChange(val){
      this.multipleSelection = val
    },
    // 获取全部功能
    async getfunction(){
      const  res = await this.request.post(this.proApis.IOTCOLUMNSSELECTALL,{})
      console.log(res,'rere')
      this.tableData2 = res.data.data
    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('add', {form:this.form ,tableData:this.tableData})
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  width: 100%;
  //text-align: center;
}
</style>