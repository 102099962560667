var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("el-tag", { staticClass: "title" }, [
            _vm._v("基本读写删(持久化存储)")
          ]),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setItem({
                        name: "test-user-name",
                        value: "normalValue"
                      })
                    }
                  }
                },
                [_vm._v("set('test-user-name', 'normalValue')")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.getItem({ name: "test-user-name" })
                    }
                  }
                },
                [_vm._v("get('test-user-name')")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.delItem({ name: "test-user-name" })
                    }
                  }
                },
                [_vm._v("remove('test-user-name')")]
              )
            ],
            1
          ),
          _c("el-tag", { staticClass: "title" }, [
            _vm._v("设置session(session存储)")
          ]),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setItem({
                        name: "test-user-pwd",
                        value: "123456",
                        type: "session"
                      })
                    }
                  }
                },
                [_vm._v("set('test-user-name', 'normalValue')")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.getItem({
                        name: "test-user-pwd",
                        type: "session"
                      })
                    }
                  }
                },
                [_vm._v("get('test-user-name')")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.delItem({ name: "test-user-pwd" })
                    }
                  }
                },
                [_vm._v("remove('test-user-name')")]
              )
            ],
            1
          ),
          _c("el-tag", { staticClass: "title" }, [
            _vm._v("获取所有可以获得的数据")
          ]),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.getAll } },
                [_vm._v("getAll(持久化存储)")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.getAll({ type: "session" })
                    }
                  }
                },
                [_vm._v("getAll(session存储)")]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.clearAll } },
                [_vm._v("delAll(持久化存储)")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.clearAll({ type: "session" })
                    }
                  }
                },
                [_vm._v("delAll(session存储)")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }