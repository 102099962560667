var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              "table-loading": _vm.tableLoading,
              page: _vm.page
            },
            on: {
              "row-save": _vm.handleSave,
              "row-update": _vm.handleUpdate,
              "row-del": _vm.handleDel
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-check", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleGrade(scope.row, scope.$index)
                          }
                        }
                      },
                      [_vm._v("权限")]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "el-dialog",
            {
              attrs: { title: "菜单", visible: _vm.grade.box, width: "40%" },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.grade, "box", $event)
                }
              }
            },
            [
              _c("el-tree", {
                attrs: {
                  data: _vm.menuAll,
                  "default-checked-keys": _vm.grade.check,
                  "default-expanded-keys": _vm.grade.check,
                  "show-checkbox": "",
                  "node-key": "id"
                },
                on: { "check-change": _vm.handleGradeCheckChange }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleGradeUpdate }
                    },
                    [_vm._v("更新")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }