<template>
  <div id="home_container">
    <el-row class="item_row" :gutter="20" style="width: 100%;">
        <el-col :span="24">
            <div class="left_right">
                <div class="date" style="display:flex;align-items:center;">
                    <img src="/img/button/日历.png" alt="">
                    <span>{{nowDate}}</span>
                </div>
                <div class="group_right">
                    <el-select clearable filterable v-model="jkParams.machineModelId" placeholder="请选择" size="small">
                        <el-option v-for="item in jkoptions"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </el-col>
    </el-row>
    <el-row class="item_row" :gutter="20" style="width: 100%;">
      <el-col :span="16">
        <div class="public">
          <img src="/img/button/角标.png" alt="">
          <span class="tit">机台设备</span>
        </div>
        <div class="charts progress">
            <div class="item_ca">
                <div class="nub">
                    {{machineList.turnOnInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.turnOnInteger))" type="dashboard" :percentage="Number(((machineList.turnOnInteger/machineList.count)*100).toFixed(1))" color="#00D643"></el-progress>
                <div class="title">
                    开台
                </div>
            </div>
            <div class="item_ca">
                <div class="nub">
                    {{machineList.downInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.downInteger))" type="dashboard" :percentage="Number(((machineList.downInteger/machineList.count)*100).toFixed(1))" color="#FFAA00"></el-progress>
                <div class="title">
                    品种
                </div>
            </div>
            <div class="item_ca">
                <div class="nub">
                    {{machineList.offlineInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.offlineInteger))" type="dashboard" :percentage="Number(((machineList.offlineInteger/machineList.count)*100).toFixed(1))" color="#FF002B"></el-progress>
                <div class="title">
                    落纱
                </div>
            </div>
            <div class="item_ca">
                <div class="nub">
                    {{machineList.alreadyInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.alreadyInteger))" type="dashboard" :percentage="Number(((machineList.alreadyInteger/machineList.count)*100).toFixed(1))" color="#00D4D6"></el-progress>
                <div class="title">
                    车速
                </div>
            </div>
            <div class="item_ca">
                <div class="nub">
                    {{machineList.waitInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.waitInteger))" type="dashboard" :percentage="Number(((machineList.waitInteger/machineList.count)*100).toFixed(1))" color="#3F74FB"></el-progress>
                <div class="title">
                    耗能
                </div>
            </div>
            <div class="item_ca">
                <div class="nub">
                    {{machineList.malfunctionInteger}}
                </div>
                <el-progress v-if="!isNaN(parseInt(machineList.malfunctionInteger))" type="dashboard" :percentage="Number(((machineList.malfunctionInteger/machineList.count)*100).toFixed(1))" color="#FF7E7E"></el-progress>
                <div class="title">
                    效率
                </div>
            </div>
        </div>

      </el-col>
      <el-col :span="8">
        <div class="public">
          <img src="/img/button/角标.png" alt="">
          <span class="tit">生产效率</span>
        </div>
        <div class="charts progress">
          <div class="item_ca"  style="display:flex;align-items:center;flex-wrap:wrap;width:50%;padding:15px;">
            <div class="title" style="width:100%">实际效率</div>
            <el-progress style="width:100%" type="line"  :percentage="70" color="#FFAA03" :show-text="false" :stroke-width="16"></el-progress>
            <p style="width:100%;text-align:center;color:#FFAA03">95%</p>
          </div>
          <div class="item_ca" style="display:flex;align-items:center;flex-wrap:wrap;width:50%;padding:15px;">
              <div class="title" style="width:100%">理论效率</div>
              <el-progress style="width:100%" type="line" class="green" :percentage="40" color="#00D546" :show-text="false" :stroke-width="16"></el-progress>
              <p style="width:100%;text-align:center;color:#00D546">95%</p>
          </div>
        </div>
      </el-col>
     
    </el-row>
    <el-row class="item_row" :gutter="20" style="width: 100%;">
      <el-col :span="8">
        <div class="public">
          <img src="/img/button/角标.png" alt="">
          <span class="tit">班制吨纱能耗展示</span>
        </div>
        <div class="charts container">
            <div class="btn" style="margin-left:0;">
                <el-radio-group v-model="btnType" size="medium" @change="clChange">
                    <el-radio-button label="1">当前:100度</el-radio-button>
                    <el-radio-button label="2">早上:1200度</el-radio-button>
                    <el-radio-button label="3">晚上:31000度</el-radio-button>
                </el-radio-group>
            </div>
            <div id="banzhi" style="width: 100%;height:18.75rem;"></div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="public">
          <img src="/img/button/角标.png" alt="">
          <span class="tit">品种支数吨纱能耗展示</span>
        </div>
        <div class="charts container">
          <div id="pinzhong" style="width: 100%;height:18rem;"></div>
        </div>
      </el-col>
      <el-col :span="8">
          <div class="public">
              <img src="/img/button/角标.png" alt="">
              <span class="tit">设备效率排名展示</span>
          </div>
          <div class="charts container">
              <div id="shebei" style="width: 100%;height:18rem;"></div>
          </div>
      </el-col>
    </el-row>
    <el-row class="item_row" :gutter="20" style="width: 100%;">
      <el-col :span="24">
        <div class="public">
          <img src="/img/button/角标.png" alt="">
          <span class="tit">机台实施状态</span>
        </div>
        <div class="charts container">
          <div id="jitai" style="width: 100%;height:18rem;"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { dateFormat } from '@/filters/'
export default {
  name: "wel",
  data() {
    return {
      nowDate:'',
      actualEfficiency:0,
      theoryEfficiency:0,
      machineList:{
        alreadyInteger: '', // 已开台 / 机台总数 = 开台率
        count: '', //机台总数
        downInteger: '',// 停机中
        malfunctionInteger: '', // 故障/总数 = 故障率
        normalInteger: '', // 正常的机器
        offlineInteger: '',// 离线中
        turnOnInteger: '',// 运行中
        waitInteger: '' // 带开台 / 总数 = 带开台率
      },
      percentage: 73.2,
      btnType: '1',
      btnType2: '1',
      jkParams: {
        machineModelId: '',
        code:''
      },
      jkoptions: [],
      bhoptions: [],
      pxoptions: [],
      fullscreen:false,
      timer:null,
      timer2:null,
      timer3:null,
      teamName:'',
      onDutyPersonnel:0,
      flag:0 ,// 0倒数 1正序
      flag2:0 ,// 0倒数 1正序
      ryclTimer:null ,
      allTimer:null ,
      zfpzNum:0,
      nowTimer:null,
    };
  },
  computed: {},
        created() {
  },
        mounted() {
            this.getbanzhi();
            this.getpinzhong();
            this.getshebei();
            this.getjitai();
    //this.getInnt()
    //this.getCPPM() // 获取昨日人员产品排名
    //this.getBZCL() // 获取昨日班组产品排名
    //this.getCode() // 编号
    //this.getEquipmentModel() // 机型
    //this.allTimer =  setInterval(()=>{
    //  clearInterval(this.timer)
    //  clearInterval(this.time2)
    //  clearInterval(this.timer3)
    //  this.getInnt()
    //},600000)
    ////this.ryclTimer =  setInterval(()=>{
    //  this.getCPPM() // 获取昨日人员产品排名
    //  this.getBZCL() // 获取昨日班组产品排名
    ////},20000)
    //this.nowTimer = setInterval(()=>{
    //  this.nowDate = dateFormat(new Date())
    //},1000)
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.time2)
    clearInterval(this.timer3)
    clearInterval(this.ryclTimer)
    clearInterval(this.allTimer)
    clearInterval(this.nowTimer)
  },
        methods: {
            //班制吨纱
            async getbanzhi() {
                var chartDom = document.getElementById('banzhi');
                var myChart = this.$echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#283b56'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisLine: {
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            margin: 12.5
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color:'#ECEBF3',
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            show:true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: '#ECEBF3',
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '25%',
                        top:'4%',
                        containLabel: true
                    },
                    series: [{
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line',
                        symbol: 'none',
                        smooth:0.6,
                        lineStyle: {
                            normal: {
                                color: 'rgb(103,98,187)'
                            }
                        },
                        itemStyle: {
                            color: "rgb(103,98,187)"
                        },
                        areaStyle: {
                            normal: {
                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                                    offset: 0,
                                    color: 'rgba(103,98,187,0.6)'
                                },{
                                    offset: 1,
                                        color: 'rgba(103,98,187,0.00)'
                                }])

                            }
                        }
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //品种支数
            async getpinzhong() {
                var chartDom = document.getElementById('pinzhong');
                var myChart = this.$echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#283b56'
                            }
                        }
                    },
                    xAxis: {
                        type: 'value',
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                        axisTick: {
                            alignWithLabel: true,
                            show: false,
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'10%',
                        containLabel: true
                    },
                    series: [{
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'bar',
                        barWidth: '50%',
                        itemStyle: {
                            normal: {
                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{

                                    offset: 0,
                                    color: 'rgba(72,177,250,1)'
                                },{
                                    offset: 1,
                                        color: 'rgba(126,222,255,1)'
                                    }]),
                                barBorderRadius: [0,15,15,0]

                            }
                        }
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //设备效率
            async getshebei() {
                var chartDom = document.getElementById('shebei');
                var myChart = this.$echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#283b56'
                            }
                        }
                    },
                    title: {
                        subtext: '前五名',
                        subtextStyle: {
                            fontSize: 12
                        },
                        right: 0,
                        top:-8,
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
                        axisTick: {
                            alignWithLabel: true,
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: "效率{%}",
                        nameGap: 10,
                        nameTextStyle: {
                            color: "#666"
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top: '10%',
                        containLabel: true
                    },
                    series: [{
                        data: [820, 932, 901, 934, 1290],
                        type: 'bar',
                        barWidth:'25%',
                        itemStyle: {
                            normal: {
                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                                    offset: 0,
                                    color: 'rgba(9,196,205,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(165,253,241,1)'
                                }])

                            }
                        }
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //机台实时
            async getjitai() {
                var chartDom = document.getElementById('jitai');
                var myChart = this.$echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#283b56'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
                        axisTick: {
                            alignWithLabel: true,
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#ACACAC',
                            }
                        },
                    },
                    grid: {
                        left: '1%',
                        right: '1%',
                        bottom: '3%',
                        top: '10%',
                        containLabel: true
                    },
                    series: [
                        {
                            data: [820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290],
                            type: 'bar',
                            barWidth: '25%',
                            itemStyle: {
                                normal: {
                                    //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                                        offset: 0,
                                        color: 'rgba(65,201,249,1)'
                                    }, {
                                        offset: 1,
                                        color: 'rgba(35,157,240,1)'
                                    }])

                                }
                            }
                        },
                        {
                            data: [820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290],
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {
                                color: '#CB8966'
                            },
                            itemStyle: {
                                color: "#CB8966"
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => { myChart.resize(); });
            },
    //  设备机型
    async getEquipmentModel() {
      const  res = await this.request.post(this.proApis.MACHINEMODELSELECTALL)
      this.jkoptions = res.data
    },
    // 编号
    async getCode() {
      const  res = await this.request.post(this.proApis.SELECTMACHINEBYGORP,{})
      this.bhoptions = res.data.data.items
    },
    //10分钟刷新一次所有数据
            getInnt() {
      this.getStatus()
      this.getBZInfo()
      this.getDBInfo()
      this.getZFPZ()
      this.getProducingEfficiency()
      this.getMachineYield(1)
      this.getProductionEnergyConsumption(1)
      this.getJTJC()
    },
    // 获取班组信息
    async getBZInfo(){
      const res = await this.request.get(this.proApis.SCHEDULESELECTGROUPNAMEBYTIME+'?date='+dateFormat(new Date()))
      res.data.data.forEach(item=>{
        this.teamName = item.name + '/'
      })
      this.teamName = this.teamName.substr(0,this.teamName.length-1);
    },
    // 获取当班人数
    async getDBInfo(){
      const res = await this.request.get(this.proApis.SCHEDULEUSERSELECTNUMBER+'?belongDate='+dateFormat(new Date()))
      this.onDutyPersonnel = res.data.data
    },
    // 获取在纺品种
    async getZFPZ(){
        const res = await this.request.get(this.proApis.SPECSHEETQUERYALLPRODUCT)
        console.log(res,"品种")
      this.zfpzNum = res.data.data
    },
    // 获取生产效率
    async getProducingEfficiency(){
      const res = await this.request.get(this.proApis.SPECSHEETMACHINEFACTQUERYEFFICIENCY)
      console.log(res.data.data,'生产效率')
      this.actualEfficiency = res.data.data.actual * 100 >100? 100 : res.data.data.actual * 100
      this.theoryEfficiency = res.data.data.theory * 100>100? 100 : res.data.data.theory * 100
    },
    // 机台筛选
    jtChange(){
      if(this.time2) clearInterval(this.time2)
      this.getJTJC()
    },
    // 获取根据设备状态查询数量
    async getStatus(){
      const res = await this.request.get(this.proApis.MACHINESELECTSTATUS)
      this.machineList = res.data
    },
    // 获取昨日人员产品排名
    async getCPPM(){
      if (this.flag === 1) {
        this.flag = 0
      }else {
        this.flag = 1
      }
      const res = await this.request.get(this.proApis.CORPUSERSYIELDSELECTUSERYIELD+'?flag='+this.flag)
      let xData = []
      let yData = []
      res.data.data.forEach(item => {
        xData.unshift(item.totalOutputYield)
        yData.unshift(item.userName)
      })
      this.setChartsForRY(xData,yData)
    },
    // 获取昨日班组产品排名
    async getBZCL(){
      if (this.flag2 === 1) {
        this.flag2 = 0
      }else {
        this.flag2 = 1
      }
      const res = await this.request.get(this.proApis.DEPARTMENTYIELDSELECTGROUPYIELD+'?flag='+this.flag2)
      let xData = []
      let yData = []
      res.data.data.forEach(item => {
        xData.unshift(item.totalOutputYield)
        yData.unshift(item.groupName)
      })
      this.setChartsForBZ(xData,yData)
    },
    // 获取机台检测
    async getJTJC(){
      const res = await this.request.post(this.proApis.SELECTALLBYCORPORATIONID,this.jkParams)
      if(res.data.code == '0000'){
        this.setChartsForJK(res.data.data)
      } else {
        this.setChartsForJK([])
      }

    },
    // 产量变化
    clChange(){
      this.getMachineYield(this.btnType)
    },
    // 获取生产产量
    async getMachineYield(type){
      let dayTime = dateFormat(new Date())
      let api
      if(type == 1){
        api = this.proApis.MACHINEYIELDSELECTYIELDTODAY+"?dayTime="+dayTime // 当天
      }else if( type == 2) {
        api = this.proApis.MACHINEDATEYIELDSELECTBYDATE+"?monthTime="+dayTime //当月
      }else if( type == 3) {
        api = this.proApis.MACHINEDATEYIELDSELECTBYMONTH+"?dateTime="+dayTime //当年
      }
      clearInterval(this.timer)
      const res = await this.request.get(api)
      this.setChartsForCL(res.data)
    },
    // 机台检测配置
    setChartsForJK(chartsData) {
      const myChart = this.$echarts.init(document.getElementById('prodCharts'));
      var option = {
        tooltip: {
          trigger: 'axis',
          show: true,
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#00C5D8'
            }
          },
          formatter:(params)=>{
            return `${params[0].marker}机台:${params[0].name.split('#&')[0]}<br>
                    ${params[0].marker}车速:${chartsData[params[0].name.split('#&')[1]].spindleSpeed}<br>
                    ${params[0].marker}台时产量:${chartsData[params[0].name.split('#&')[1]].decimal}<br>
                    ${params[0].marker}效率:${Number(chartsData[params[0].name.split('#&')[1]].effectiveness).toFixed(3)}`
          }

        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel:{
              interval: 0,
              formatter:function(value){
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 2;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (chartsData.length>10 && rowN > 1)//如果类目项的文字大于3,
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret.split('#&')[0];
                }
                else {
                  // console.log(value)
                  return value.split('#&')[0];
                }
              }
            },
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].machine)
              }
              return res;
            })()
          },
          {
            type: 'category',
            show: false,
            boundaryGap: true,
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].machine)
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '效率',
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            show: false,
            scale: true,
            name: '预购量',
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '机台',
            barWidth: 20,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(14,200,218,0.8)'},
                    {offset: 0, color: 'rgba(14,200,218,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(14,200,218,0.8)'},
                      {offset: 0, color: 'rgba(14,200,218,0.3)'}
                    ]
                )
              }
            },
            type: 'bar',
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].effectiveness)
              }
              return res;
            })()
          }
        ]
      };
      if (chartsData.length > 2 ) {
        let app = {}
        app.count = 2;
        this.time2 = setInterval(function () {
          let axisData1 = chartsData[app.count].machine;
          let axisData2 = chartsData[app.count].machine;
          let axisData3 = chartsData[app.count].effectiveness;

          let data0 = option.series[0].data;
          data0.shift();
          data0.push(axisData3);
          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData2);
          app.count ++
          if(app.count === chartsData.length) {
            app.count = 0
          }
          myChart.dispatchAction({
            type: 'showTip',
            //屏幕上的x坐标
            x: 245,
            //屏幕上的y坐标
            y: 122,
          });
          myChart.setOption(option, false);


        }, 2100);
        myChart.on('mouseover',(even)=>{
          // console.log(even,'even')
          clearInterval(this.time2)
        })
        myChart.on('mouseout',(even)=>{
          clearInterval(this.time2)
          this.time2 = setInterval(function () {
            let axisData1 = chartsData[app.count].machine;
            let axisData2 = chartsData[app.count].machine;
            let axisData3 = chartsData[app.count].effectiveness;

            let data0 = option.series[0].data;
            data0.shift();
            data0.push(axisData3);
            option.xAxis[0].data.shift();
            option.xAxis[0].data.push(axisData1);
            option.xAxis[1].data.shift();
            option.xAxis[1].data.push(axisData2);
            app.count ++
            if(app.count === chartsData.length) {
              app.count = 0
            }
            myChart.dispatchAction({
              type: 'showTip',
              //屏幕上的x坐标
              x: 245,
              //屏幕上的y坐标
              y: 122,
            });
            myChart.setOption(option, false);


          }, 2100);
        })
      }else {
        myChart.setOption(option, false);
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,  // 显示第几个series
          dataIndex: 0// 显示第几个数据
        });
        myChart.on('mouseout',(even)=>{
          if(this.time2) clearInterval(this.time2)
          this.time2 = setInterval(function () {
            myChart.setOption(option, false);
            myChart.dispatchAction({
              type: 'showTip',
              seriesIndex: 0,  // 显示第几个series
              dataIndex: 0// 显示第几个数据
            });
          },5000)
        })
      }

    },
    // 生产产量配置
    setChartsForCL(echartData) {

      const myChart = this.$echarts.init(document.getElementById('output'));
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              let now = echartData.times
              let res = [];
              let len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            show: false,
            data: (function () {
              var now = echartData.times
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '产量明细',
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '累计产量',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '产量明细',
            type: 'bar',
            barWidth: 20,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var now = echartData.decimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(54,176,254,0.8)'},
                    {offset: 0, color: 'rgba(54,176,254,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(54,176,254,0.8)'},
                      {offset: 0, color: 'rgba(54,176,254,0.3)'}
                    ]
                )
              }
            },
          },
          {
            name: '累计产量',
            type: 'line',
            data: (function () {
              var now = echartData.allDecimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(54,176,254,0.8)'},
                    {offset: 0, color: 'rgba(54,176,254,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(54,176,254,0.8)'},
                      {offset: 0, color: 'rgba(54,176,254,0.3)'}
                    ]
                )
              }
            },
          }
        ]
      };
      let app = {}
      app.count = 11;
      this.timer = setInterval(function () {

        let axisData1 = echartData.times[app.count];
        let axisData2 = echartData.decimal[app.count];
        let axisData3 = echartData.allDecimal[app.count];

        let data0 = option.series[0].data;
        let data1 = option.series[1].data;
        data0.shift();
        data0.push(axisData2);
        data1.shift();
        data1.push(axisData3);

        option.xAxis[0].data.shift();
        option.xAxis[0].data.push(axisData1);
        option.xAxis[1].data.shift();
        option.xAxis[1].data.push(axisData1);
        app.count ++
        if(app.count === echartData.times.length) {
          app.count = 0
        }
        myChart.setOption(option,true);
      }, 2100);


      // 鼠标移入后停止移动
      myChart.on('mouseover',(even)=>{
        clearInterval(this.timer)
      })
      myChart.on('mouseout',(even)=>{
        clearInterval(this.timer)
        this.timer = setInterval(function () {
          let axisData1 = echartData.times[app.count];
          let axisData2 = echartData.decimal[app.count];
          let axisData3 = echartData.allDecimal[app.count];

          let data0 = option.series[0].data;
          let data1 = option.series[1].data;
          data0.shift();
          data0.push(axisData2);
          data1.shift();
          data1.push(axisData3);

          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData1);
          app.count ++
          if(app.count === echartData.times.length) {
            app.count = 0
          }
          myChart.setOption(option,true);
        }, 2100);
      })
    },
    // 当日人员平均产量排名
    setChartsForRY(xData,yData) {
      const myChart = this.$echarts.init(document.getElementById('average'));
      var option = {
        xAxis: {
          type: 'value',
          boundaryGap: false,
        },
        grid: {
          x: '20px', //相当于距离左边效果:padding-left
          y: '20px',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',

          data: yData
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: [{
          data: xData,
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [0, 15, 15, 0],
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#E2B3F7'},
                    {offset: 0, color: '#C17CED'}
                  ]
              ),
            }
          },
          emphasis: {
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#E2B3F7'},
                    {offset: 0, color: '#C17CED'}
                  ]
              )
            }
          },
        }]
      };
      myChart.setOption(option);
    },
    // 当日班组平均产量排名
    setChartsForBZ(xData,yData) {
      const myChart = this.$echarts.init(document.getElementById('groupCharts'));
      var option = {
        xAxis: {
          type: 'value',
          boundaryGap: false,
        },
        grid: {
          x: '20px', //相当于距离左边效果:padding-left
          y: '20px',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: yData
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: [{
          data: xData,
          type: 'bar',
          barWidth:20,
          itemStyle: {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [0, 15, 15, 0],
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#88DAFF'},
                    {offset: 0, color: '#45B2FF'}
                  ]
              ),
            }
          },
          emphasis: {
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#88DAFF'},
                    {offset: 0, color: '#45B2FF'}
                  ]
              )
            }
          },
        }]
      };
      myChart.setOption(option);
    },
    // 获取生产能耗
    async getProductionEnergyConsumption(type){
      let dayTime = dateFormat(new Date())
      let api
      if(type == 1){
        api = this.proApis.SELECTENERGYCONSUMPTIONTODAY+"?dayTime="+dayTime // 当天
      }else if( type == 2) {
        api = this.proApis.SELECTBYMONTH+"?monthTime="+dayTime //当月
      }else if( type == 3) {
        api = this.proApis.SELECTBYYEAR+"?dateTime="+dayTime //当年
      }
      clearInterval(this.timer3)
      const res = await this.request.get(api)
      console.log(res,'resss')
      this.setChartsForNH(res.data)
    },
    // 生产能耗变化
    nhChange(){
      this.getProductionEnergyConsumption(this.btnType2)
    },
    // 生产能耗
    setChartsForNH(echartData) {

      const myChart = this.$echarts.init(document.getElementById('energy'));
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              let now = echartData.times
              let res = [];
              let len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            show: false,
            data: (function () {
              var now = echartData.times
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '累计能耗',
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '能耗',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '能耗明细',
            type: 'bar',
            barWidth: 20,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var now = echartData.decimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(72,60,255,0.8)'},
                    {offset: 0, color: 'rgba(72,60,255,0.3)'},
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(72,60,255,0.8)'},
                      {offset: 0, color: 'rgba(72,60,255,0.3)'},
                    ]
                )
              }
            },
          },
          {
            name: '累计能耗',
            type: 'line',
            data: (function () {
              var now = echartData.allDecimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(72,60,255,0.8)'},
                    {offset: 0, color: 'rgba(72,60,255,0.3)'},
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(72,60,255,0.8)'},
                      {offset: 0, color: 'rgba(72,60,255,0.3)'},
                    ]
                )
              }
            },
          }
        ]
      };
      let app = {}
      app.count = 11;
      this.timer3 = setInterval(function () {
        let axisData1 = echartData.times[app.count];
        let axisData2 = echartData.decimal[app.count];
        let axisData3 = echartData.allDecimal[app.count];

        let data0 = option.series[0].data;
        let data1 = option.series[1].data;
        data0.shift();
        data0.push(axisData2);
        data1.shift();
        data1.push(axisData3);

        option.xAxis[0].data.shift();
        option.xAxis[0].data.push(axisData1);
        option.xAxis[1].data.shift();
        option.xAxis[1].data.push(axisData1);
        app.count ++
        if(app.count === echartData.times.length) {
          app.count = 0
        }
        myChart.setOption(option,true);
      }, 2100);
      myChart.on('mouseover',(even)=>{
        clearInterval(this.timer3)
      })
      myChart.on('mouseout',(even)=>{
        clearInterval(this.timer3)
        this.timer3 = setInterval(function () {
          let axisData1 = echartData.times[app.count];
          let axisData2 = echartData.decimal[app.count];
          let axisData3 = echartData.allDecimal[app.count];

          let data0 = option.series[0].data;
          let data1 = option.series[1].data;
          data0.shift();
          data0.push(axisData2);
          data1.shift();
          data1.push(axisData3);

          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData1);
          app.count ++
          if(app.count === echartData.times.length) {
            app.count = 0
          }
          myChart.setOption(option,true);
        }, 2100);
      })
    },
    // 全屏事件
    handleFullScreen() {
      // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
      let element = document.getElementById('home_container');//设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>

<style lang="scss" scoped>
#home_container {
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .item_row {
    margin: 0.25rem 0;

    .left {
      width: 100%;
      border-radius: 0.625rem;
      height: 4.375rem;
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      padding: 0.625rem;
      box-sizing: border-box;
      a {
        color: #FFFFFF;
      }
      .last {
       .el-button {
         height: 50px;
         a {
           color: #000;
         }
       }
      }
    }
    .left_right {
      width: 100%;
      border-radius: 0.625rem;
      height: 5.1875rem;
      background-color: #fff;
      padding: 0.625rem 1rem;
      align-items:center;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
      }

      span {
        font-size: 20px;
        margin-left: 1.25rem;
      }
      .group_right {
        font-size: 20px;
        /*margin-top: 20px;*/
      }
    }
    .group {
      font-size: 1.25rem;
      position: relative;
      top: 0.625rem;
    }

    .kanban {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      .num {
        background-color: #fff;
        width: 14.25rem;
        border-radius: 0.625rem;
        height: 4.375rem;

        .content {
          height: 4.375rem;
          position: relative;

          img {
            position: absolute;
            width: 2.8125rem;
            height: 2.8125rem;
            top: 50%;
            left: 1.875rem;
            transform: translateY(-50%);
          }

          .title {
            width: 100%;
            text-align: center;
            font-size: 0.9375rem;
            margin-top: 0.5rem;
          }

          .numb {
            width: 100%;
            text-align: center;
            font-size: 1.625rem;
            font-weight: 700;
          }
        }
      }
    }

    .public {
      width: 100%;
      display: flex;
      border-radius: 0.625rem 10px 0 0;
      height: 2.8125rem;
      padding: 0.25rem 0 0 0.8125rem;
      background-color: #fff;
      border-bottom: 1px solid #EBEBEB;
      box-sizing: border-box;

      img {
        height: 1.15rem;
        margin-right: 0.625rem;
        position: relative;
        top: 0.6rem;
      }

      .tit {
        font-size: 1.25rem;
        position: relative;
        top: 0.25rem;
      }

      span {
        font-weight: 700;
      }
    }

    .select_container {
      background-color: #fff;
      //padding-top: 10px;
      //display: inline-block;

    }

    .charts {
      background-color: #fff;
      width: 100%;
      border-radius: 0 0 10px 10px;
    }

    .container {
      height: 18.75rem;
      position: relative;

      .btn {
        padding-top: 1.25rem;
        text-align: center;
        z-index: 99999;
        margin-left: 2.5rem;
      }
    }

    .lineP {
      height: 150px;
      padding: 1.25rem;
      padding-left: 2.25rem;
      box-sizing: border-box;
      display: flex;

      .el-progress {
        width: 100%;
      }

      /deep/ .el-progress-bar {
        width: 100%;
        margin: 0 0 0.5rem 0;
      }

      /deep/ .el-progress__text {
        width: 84%;
        text-align: center;
        color: #FFAA00;
      }

      //.item_line {
      //  width: 50%;
      //  //margin: 2.5rem 0;
      //  .green {
      //    /deep/ .el-progress__text {
      //      color: #00D643;
      //    }
      //  }
      //
      //  .title {
      //    font-size: 1.125rem;
      //    position: relative;
      //    left: -1.5rem;
      //    color: #333333;
      //    text-align: center;
      //    font-weight: 700;
      //    margin-bottom: 1rem;
      //    margin-top: 1rem;
      //  }
      //}
    }

    .progress {
      height: 9.375rem;
      display: flex;
      justify-content: space-around;
      padding: 1.25rem;
      box-sizing: border-box;

      /deep/ .el-progress-circle {
        height: 5rem !important;
        width: 5rem !important;
      }

      .item_ca {
        position: relative;

        .title {
          text-align: center;
          color: #747E8E;
          font-size: 1rem;
        }

        .nub {
          font-size: 1.25rem;
          color: #747E8E;
          text-align: center;
          font-weight: 700;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        /deep/ .el-progress--dashboard {
          transform: rotateX(180deg);
        }

        /deep/ .el-progress__text {
          transform: translateY(-50%) rotateX(180deg);
        }

      }
    }
  }
}
</style>
