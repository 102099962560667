var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("basic-container", [
        _c("div", { staticClass: "colorful" }, [
          _vm._v(_vm._s(_vm.isGrayMode ? "GRAY" : "COLORFUL"))
        ])
      ]),
      _c(
        "basic-container",
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-question", type: "primary" },
              on: { click: _vm.handleSwitch }
            },
            [_vm._v(_vm._s(_vm.isGrayMode ? "关闭灰度模式" : "打开灰度模式"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }