<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuLeft">
            <el-button type="primary" size="small" icon="el-icon-discover" v-bind:disabled="disabled">审核</el-button>
            <el-button type="warning" size="small" icon="el-icon-discover" v-bind:disabled="disabled">撤销审核</el-button>
        </template>
        <template slot="menuRight">
            <el-input v-model="nameOrCode" placeholder="设备名称或编码" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button>
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove} from "@/api/workShopParam"
export default {
    data() {
        return {
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount:5,
                total: 10,
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableOption:{
              delBtn: true,
              menuWidth:200,
              selection: true,
              tip: false,
              columnBtn : false,
              column:[{
                label: "故障记录编号",
                prop: "id"
              },{
                label: "设备名称",
                prop: "machineName"
              },{
                label: "设备编码",
                prop: "machineCode"
              },{
                label: "开始时间",
                prop: "beginTime"
              },{
                label: "结束时间",
                prop: "endTime"
              },{
                label: "备注",
                prop: "remarks"
              },{
                label: "是否处置完成",
                prop: "isFinish",
                dicData: [{
                    label: "是",
                    value: 1
                },{
                    label: "否",
                    value: 0
                }]
              },{
                label: "创建时间",
                prop: "createTime"
              },{
                label: "创建用户",
                prop: "createUserid"
              },{
                label: "处理完结确认时间",
                prop: "confirmTime"
              },{
                label: "处理完结确认用户",
                prop: "confirmUserid"
              },{
                label: "公司名称",
                prop: "corporationId"
              },{
                label: "部门名称",
                prop: "corporationId"
              }]
            }
        }
    },
    created() {
      this.list();
    },
    methods: {
        handleDel(row) {
            this.$confirm("确定要删除吗","提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove(row.id).then(() => {
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrCode).then(res => {
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>