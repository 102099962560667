var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { staticClass: "menu-header" },
                [
                  _c(
                    "el-button-group",
                    [
                      _vm.permission.sys_menu_btn_add
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleAdd.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("新增")]
                          )
                        : _vm._e(),
                      _vm.permission.sys_menu_btn_edit
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleEdit.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.permission.sys_menu_btn_del
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-delete",
                                size: "small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleDel.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                { attrs: { width: "300px" } },
                [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.menuAll,
                      "node-key": "id",
                      "highlight-current": "",
                      "default-expand-all": "",
                      "expand-on-click-node": false
                    },
                    on: { "node-click": _vm.handleNodeClick }
                  })
                ],
                1
              ),
              _c(
                "el-main",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "80px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "父节点ID" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.parentForm.id,
                              callback: function($$v) {
                                _vm.$set(_vm.parentForm, "id", $$v)
                              },
                              expression: "parentForm.id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "父节点" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.parentForm.label,
                              callback: function($$v) {
                                _vm.$set(_vm.parentForm, "label", $$v)
                              },
                              expression: "parentForm.label"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "菜单名称" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.formGrade },
                            model: {
                              value: _vm.form.label,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "label", $$v)
                              },
                              expression: "form.label"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "菜单图标" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.formGrade },
                            model: {
                              value: _vm.form.icon,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "icon", $$v)
                              },
                              expression: "form.icon"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "菜单路径" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.formGrade },
                            model: {
                              value: _vm.form.href,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "href", $$v)
                              },
                              expression: "form.href"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _vm.formStatus == "add"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.formGrade
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [_vm._v("新增")]
                              )
                            : _vm._e(),
                          _vm.formStatus == "edit"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.formGrade
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }