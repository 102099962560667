var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        { staticClass: "guide-container" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-question", type: "primary" },
              on: { click: _vm.guide }
            },
            [_vm._v("运行引导页")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }