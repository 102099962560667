var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              "table-loading": _vm.tableLoading,
              page: _vm.page
            },
            on: {
              "row-save": _vm.handleSave,
              "row-update": _vm.handleUpdate,
              "row-del": _vm.handleDel
            },
            scopedSlots: _vm._u([
              {
                key: "state",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.state == 0 ? "success" : "danger"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.findByvalue(scope.dic, scope.row.state))
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }