var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("el-tag", [
            _vm._v("具体配置build/webpack.base.conf.js配置环境变量的地方")
          ]),
          _c("h4", [_vm._v(_vm._s(_vm.envText))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }