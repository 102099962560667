var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("p", [_vm._v("请打开浏览器控制台，然后点击下面的按钮")]),
      _c(
        "el-button",
        { attrs: { type: "danger" }, on: { click: _vm.handleNewError } },
        [_vm._v("触发一个错误")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }