var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "el-row",
        { staticClass: "btn-list", attrs: { span: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "item", attrs: { md: 3, xs: 8, sm: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.$avueRouter.closeTag()
                    }
                  }
                },
                [_vm._v("关闭本标签")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "item", attrs: { md: 3, xs: 8, sm: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.$avueRouter.closeTag("/wel/index")
                    }
                  }
                },
                [_vm._v("关闭其他标签（首页）")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }