import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


//��ȡ���۶����б���
export const saleOrderDetailList = (page, pageSize, businessnoin, businessnoout, beginOrderDate,  endOrderDate) => {
    return request({
        url: pvhApiBase + "Tracing/GetFactorySaleOrderDetails",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "beginOrderDate": beginOrderDate,
            "endOrderDate": endOrderDate,
            "page": page,
            "pageSize": pageSize 
        }
    })
}


