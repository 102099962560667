var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pull-chheight role-container" },
    [
      _c(
        "basic-container",
        [
          _c("p", [
            _vm._v(
              "当前用户的权限值是有权限时(admin) 时，才可以看到下面的个别按钮。"
            )
          ]),
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-value": "admin",
              "active-text": "有权限(admin)",
              "inactive-value": "user",
              "inactive-text": "无权限(user)"
            },
            on: { change: _vm.handlechange },
            model: {
              value: _vm.roleSwitch,
              callback: function($$v) {
                _vm.roleSwitch = $$v
              },
              expression: "roleSwitch"
            }
          })
        ],
        1
      ),
      _c(
        "basic-container",
        [
          _c(
            "el-row",
            { staticClass: "btn-list", attrs: { span: 24 } },
            [
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn1
                    ? _c("el-button", [_vm._v("默认按钮")])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn2
                    ? _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("主要按钮")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn3
                    ? _c("el-button", { attrs: { type: "success" } }, [
                        _vm._v("成功按钮")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn4
                    ? _c("el-button", { attrs: { type: "info" } }, [
                        _vm._v("信息按钮")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn5
                    ? _c("el-button", { attrs: { type: "warning" } }, [
                        _vm._v("警告按钮")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "item", attrs: { md: 2, xs: 8, sm: 6 } },
                [
                  _vm.permission.sys_role_btn6
                    ? _c("el-button", { attrs: { type: "danger" } }, [
                        _vm._v("危险按钮")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }