<template>
  <div class="login-header">
    <div class="login-img-list">
      <img src="/img/login/logo01.png" class="oneImg" alt="" />
      <div class="line"></div>
      <img src="/img/login/logo02.png" class="twoImg" alt="" />
    </div>
    <div class="login-nav">
      <div class="nav-list">
        <div class="nav-item" v-for="(item, index) in navList" :key="index">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          title: "首页",
          path: "#",
        },
        {
          title: "应用效果",
          path: "#",
        },
        {
          title: "技术优势",
          path: "#",
        },
        {
          title: "合作伙伴",
          path: "#",
        },
      ],
    };
  },
};
</script>


<style lang="less" scoped>
.login-header {
  background: #7e7e7e;
  opacity: 0.49;

  height: 80px;
  display: flex;
  padding: 0 200px 0 30px;
  justify-content: space-between;
  align-items: center;


  .login-img-list {
    display: flex;
    align-items: center;
    .line {
    width: 2px;
    height: 50px;
    border-left: 1px solid #fff;
    margin: 0 20px;
  }
    .oneImg {
      margin-left: 10px;
      width: 146px;
      height: 50px;
    }
     .twoImg{
        width: 97px;
        height: 44px;
     }
  }

  .nav-list {
    display: flex;
    .nav-item {
      margin: 0 20px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>

