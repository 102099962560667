<template>
  <div class="volumeSet">
     <el-dialog
        title="修改"
        :visible.sync="modelShow"
        width="70%"
        :before-close="handleClose">
        <avue-crud :option="tableOption"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 >
                  
<template slot-scope="scope" slot="menu">
  <div  style="height:30px;" v-if="scope.row.status == 0"/>
        <el-button type="text"
                   size="small"
                   plain
                   class="none-border"
                   @click.stop="del(scope.row,scope.index)">删除
        </el-button>
      </template>
        <!-- <template slot="machineCode" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
          </el-link>
        </template> -->
        <template slot="menuRight">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="90px" style="display:flex;justify-content:space-between;flex-wrap:wrap;">
            <el-input v-model="equipment" :placeholder="$t('Equipment Name')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>
            <el-select filterable clearable v-model="page.processId" @change="pinzhong" placeholder="品种" size="small" style="width:100px !important;marginRight: 5px;">
                <!-- <el-option v-for="item in refineList" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
            </el-select>
            <el-select filterable clearable v-model="page.postId" placeholder="批号" size="small" style="width:100px !important;marginRight: 5px;">
                <!-- <el-option v-for="item in workLists" :key="item.id" :label="item.batchCode" :value="item.id"></el-option> -->
            </el-select>
            <el-form-item label="开始时间"  style="width:250px;display:flex;height:32px">
                <el-date-picker clearable
                                v-model="startTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期" style="width:100%;height:32px;">
                </el-date-picker>
            </el-form-item>
              <el-form-item label="结束时间"  style="width:250px;display:flex;height:32px">
                <el-date-picker clearable
                                v-model="endTime"
                                value-format="yyyy-MM-dd-HH-MM-SS"
                                type="date"
                                placeholder="选择日期" style="width:100%;height:32px">
                </el-date-picker>
            </el-form-item>
            <el-input v-model="output" :placeholder="$t('Please Enter Output')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>
            <el-input v-model="consumption" :placeholder="$t('Please Enter Energy Consumption')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>

           <!-- <el-select filterable clearable v-model="page.workShopId" @change="chejian" placeholder="车间" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in workshopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
           <el-select filterable clearable v-model="page.groupId" placeholder="设备" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in quipmentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
          <el-button type="primary" @click="save" size="mini" style="height:32px;padding-top: 8px;">保存</el-button>
        </el-form>
        </template>
      </avue-crud>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sub">提交</el-button>
        </span>
      </el-dialog>

  </div>
</template>

<script>

export default {
  name: "edit",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    selectItem:Object
  },
  data() {
    return {
			 output: '', //产量
       consumption: '', //能耗
			startTime: '',
			endTime:'',
			equipment:'',
      formInline: {},
      tableLoading: false,
			 showEdit:false,
			  page: {
        pageSize: 10,
        // pagerCount: 50,
        total: 10,
        pageNo: 1,
        workShopId:'',
        groupId:'', // 设备
        processId:'', //品种
        postId:'', //批号
        userName:''
      },
			tableData: [],
      tableOption: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        // dialogFullscreen: true,
        // addBtnText: this.$t('ADD'),
        // editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "设备名称",
          prop: "machineName"
        }, {
          label: "品种名称",
        //   slot: true,
          prop: "productName"
        }, {
          label: "批号",
          prop: "batchCode"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量",
          prop: "yield"
        },{
          label: "能耗",
          prop: "energy"
        }]
      }
    }
  },
  created(){
  },
  methods: {
		del() {
        console.log('弹出框的删除');
      },
      // 弹出框的保存
      save() {
        console.log('弹出框的保存');
      },
      // 弹出框的提交
      sub() {
          console.log('弹出框的提交');
      },
		 handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
			pinzhong() {},
    // 获取当前设备模型
    async getModel(){
      const  res = await this.request.get(this.proApis.MACHINEMODELSELECTBYMACHINEMODELID+'?id=' + this.selectItem.id)
      console.log(res)
      this.tableData = res.data

    },
    openInner(){
      this.innerVisible = true
      this.$nextTick(()=>{
        if(this.$refs.multipleTable) {
          this.tableData.forEach(row => {
            this.tableData2.forEach(item=>{
              if(row.id === item.id) {
                this.$refs.multipleTable.toggleRowSelection(item,true);
              }
            })
          });
        }
      })


    },
    innerSure(){
      console.log(this.multipleSelection )
      this.tableData = this.multipleSelection
      this.innerVisible = false
    },
    handleSelectionChange(val){
      this.multipleSelection = val
    },
    // 获取全部功能
    async getfunction(){
      const  res = await this.request.post(this.proApis.IOTCOLUMNSSELECTALL,{})
      console.log(res,'rere')
      this.tableData2 = res.data.data
    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('add', {form:this.form ,tableData:this.tableData})
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  width: 100%;
  //text-align: center;
}
</style>