var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("basic-container", [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("404错误页面")])]
        ),
        _c(
          "div",
          { staticClass: "error-box" },
          [
            _c("errorPage404", { staticClass: "error-page" }),
            _c("span", { staticClass: "error-text" }, [
              _vm._v(
                "/404当访问的页面不存在时会跳转到404页面，您可以在浏览器地址栏中修改url为一个不存在的路径，体验一下效果"
              )
            ])
          ],
          1
        )
      ]),
      _c("basic-container", [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("403错误页面")])]
        ),
        _c(
          "div",
          { staticClass: "error-box" },
          [
            _c("errorPage403", { staticClass: "error-page" }),
            _c("span", { staticClass: "error-text" }, [
              _vm._v(
                "/403在当前登录用户不具有执行当前操作的权限时跳转到该页面，您可以在ajax请求方法中判断返回的状态码为403时跳转到该页面"
              )
            ])
          ],
          1
        )
      ]),
      _c("basic-container", [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("500错误页面")])]
        ),
        _c(
          "div",
          { staticClass: "error-box" },
          [
            _c("errorPage500", { staticClass: "error-page" }),
            _c("span", { staticClass: "error-text" }, [
              _vm._v(
                "/500当请求之后出现服务端错误时跳转到该页面，您可以在ajax请求方法中判断返回的状态码为500时跳转到该页面"
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }