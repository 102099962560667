var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.oidcIsAuthenticated
    ? _c("div", [
        _c("p", [_vm._v("\n    You are signed in as:\n  ")]),
        _c("div", {
          staticStyle: {
            width: "100%",
            "max-width": "640px",
            height: "200px",
            margin: "0 auto",
            "font-family": "monospace"
          },
          domProps: { innerHTML: _vm._s(_vm.userDisplay) }
        }),
        _c("p", [_vm._v("\n    Id token\n  ")]),
        _c("p", [
          _vm._v(
            "\n    expires " +
              _vm._s(new Date(_vm.oidcIdTokenExp).toISOString()) +
              "\n  "
          )
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.oidcIdToken,
              expression: "oidcIdToken"
            }
          ],
          staticStyle: {
            width: "100%",
            "max-width": "640px",
            height: "200px",
            margin: "0 auto",
            "font-family": "monospace"
          },
          attrs: { readonly: "" },
          domProps: { value: _vm.oidcIdToken },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.oidcIdToken = $event.target.value
            }
          }
        }),
        _c("p", [
          _c("button", { on: { click: _vm.reauthenticate } }, [
            _vm._v("Reauthenticate silently")
          ])
        ])
      ])
    : _vm.oidcAuthenticationIsChecked
    ? _c("p", [_vm._v("You are not signed in")])
    : _c("p", [_vm._v("Silent renew is in progress...")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }