var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-header" }, [
    _vm._m(0),
    _c("div", { staticClass: "login-nav" }, [
      _c(
        "div",
        { staticClass: "nav-list" },
        _vm._l(_vm.navList, function(item, index) {
          return _c("div", { key: index, staticClass: "nav-item" }, [
            _vm._v("\n        " + _vm._s(item.title) + "\n      ")
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-img-list" }, [
      _c("img", {
        staticClass: "oneImg",
        attrs: { src: "/img/login/logo01.png", alt: "" }
      }),
      _c("div", { staticClass: "line" }),
      _c("img", {
        staticClass: "twoImg",
        attrs: { src: "/img/login/logo02.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }