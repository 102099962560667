var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-form",
            { staticClass: "demo-form-inline", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "复制内容" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "复制内容" },
                    model: {
                      value: _vm.message,
                      callback: function($$v) {
                        _vm.message = $$v
                      },
                      expression: "message"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.message,
                          expression: "message",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:error",
                          value: _vm.onError,
                          expression: "onError",
                          arg: "error"
                        }
                      ],
                      attrs: { type: "primary" }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }